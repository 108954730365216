import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import handleHospital from "@/services/modules/hospital";
import useDate from '@/services/utils/day';
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfPrinter = () => {
    const { formatDate, formatToDdMmYy } = useDate();
    const { dateOfBarthConvertToAge } = handleHospital();
    const { getHeader, getFooter } = pdfHeaderFooter();
    
    const exportToPDF = (company, notes, rxList, service, qrcode, userName, isHideHeader, isHideFooter) => {   
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 170, 25, 86 ],
            header: !isHideHeader ? getHeader(company) : {},
            footer: !isHideFooter ? getFooter(userName, qrcode) : {},
            content: getContent(doc, notes, rxList, service),
            
            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Prescription'
            }
        }

        getLeftContent(doc, notes)
        getRightContent(doc, rxList)
        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (doc, notes, rxList, service) => {
        return [
            {
                columns: [
                    {
                        alignment: 'left',
                        stack: [
                            {
                                text: ''
                            }
                        ]
                        
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: ''
                            }
                        ]
                    }
                ]
            },
            
            {
                margin: [0, 10, 0, 0],
                table: {
                    widths: [90, '*', '*', 90],
                    body: [[
                        {
                            width: 90,
                            text: service.contact_profile.full_name,
                            bold: true
                        },
                        {
                            text: `ID: ${service.contact_profile.patient_id}`,
                            bold: true
                        },
                        {
                            text: `${calculateAge(service.contact_profile.birthday)} ${service.contact_profile.gender ? '| ' + capitalize(service.contact_profile.gender) : ''}`,
                            bold: true
                        },
                        {
                            text: service.doctor_order.prescription_date ? formatToDdMmYy(service.doctor_order.prescription_date, false) : formatDate(new Date(), true),
                            alignment: 'right',
                            bold: true
                        }
                    ]],
                },
                layout: {
                    hLineWidth: function (i, node) {
					    return 2;
				    },
				    vLineWidth: function (i, node) {
					    return 0;
				    }
                }
            },

            {
                columns: [
                    {
                        alignment: 'left',
                        margin: [10, 10],
                        width: '25%',
                        stack: [],
                    },

                    {
                        canvas: [
                            {
                                type: 'line',
                                x1: 0,
                                y1: 0,
                                x2: 0, 
                                y2: 500,
                                lineWidth: 2,
                            },
                        ],
                    },

                    {
                        width: '70%',
                        stack: []
                    }
                ]
                
            },
        ]
    }

    const getRightContent = (doc, rxList) => {
        rxList.map((item) => {
            const headerName = getRightHeaderName(item);
            if(item.selectedNotes.length > 0){
                doc.content[2].columns[2].stack.push(headerName);
            }
            var ol = [];
            item.selectedNotes.map((child, index) => {
                const text = item.value == "rx" ?
                            `${child.name} \n ${ child.dose ? child.dose : ''} \t ${child.unit ? child.unit : ''} \t ${child.remarks ? child.remarks : ''} \t ${child.duration ? child.duration : ''}` : child
                ol.push(text)
            })
            doc.content[2].columns[2].stack.push({
                ol: ol
            })
        })
    }

    const calculateAge = (birthday) => {
        const { year } = dateOfBarthConvertToAge(birthday)
        return `${year}y`
    }

    const capitalize = (gender) => {
        return gender.charAt(0).toUpperCase() + gender.slice(1);
    }

    const getRightHeaderName = (item) => {
        return {
            fontSize: 14,
            bold: true,
            margin: [0, 15, 0, 5],
            text: item.name
        }
    }

    const getLeftContent = (doc, notes) => {
        notes.map((item) => {
            var phc_flag = false;
            const headerName = getLeftHeaderName(item);
            if(item.selectedNotes.length > 0){
                doc.content[2].columns[0].stack.push(headerName);
            }else if(item.value = "phc_note" && item.phcNoteText){
                doc.content[2].columns[0].stack.push(headerName);
                doc.content[2].columns[0].stack.push(item.phcNoteText);
            }
            var ul = [];
            item.selectedNotes.map((child, index) => {
                if(!phc_flag){
                    ul.push(item.value == 'investigation' ? child.name : child);
                }
            })
            if(!phc_flag){
                doc.content[2].columns[0].stack.push({
                    ul: ul
                })
            }
        })
    }

    const getLeftHeaderName = (item) => {
        return {
            fontSize: 14,
            bold: true,
            margin: [0, 15, 0, 5],
            text: item.name
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;